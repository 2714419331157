import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Testimonial = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="my-20 overflow-hidden">
      <section className="testimonial-bg">
        <div className="testimonial__overlay">
          <Carousel
            responsive={responsive}
            className="max-w-screen-xl mx-auto pt-32"
          >
            {/* 1st Slide */}
            <div className="md:mt-10">
              <div className="flex justify-center items-center">
                <div>
                  <img
                    src="https://img.freepik.com/free-photo/close-up-portrait-cheerful-glamour-girl-with-cute-make-up-smiling-white-teeth-looking-happy-camera-standing-blue-background_1258-70300.jpg"
                    alt="review IMG1"
                    className="md:w-32 md:h-32 w-20 h-20 rounded-full "
                  />
                </div>
              </div>
              <div className="text-center md:px-32 px-5 mt-5">
                <blockquote class="md:text-xl italic font-semibold text-gray-900 dark:text-white">
                  <p>
                    "Flowbite is just awesome. It contains tons of predesigned
                    components and pages starting from login screen to complex
                    dashboard. Perfect choice for your next SaaS application."
                  </p>
                </blockquote>
              </div>
            </div>
            {/* 2nd Slide */}
            <div className="mt-10">
              <div className="flex justify-center items-center">
                <div>
                  <img
                    src="https://img.freepik.com/free-photo/young-man-with-charming-smile-blue-eyes-posing_176420-15602.jpg"
                    alt="review IMG1"
                    className="md:w-32 md:h-32 w-20 h-20 rounded-full "
                  />
                </div>
              </div>
              <div className="text-center md:px-32 px-5 mt-5">
                <blockquote class="md:text-xl italic font-semibold text-gray-900 dark:text-white">
                  <p>
                    "Flowbite is just awesome. It contains tons of predesigned
                    components and pages starting from login screen to complex
                    dashboard. Perfect choice for your next SaaS application."
                  </p>
                </blockquote>
              </div>
            </div>
            {/* 3rd Slide */}
            <div className="mt-10">
              <div className="flex justify-center items-center">
                <div>
                  <img
                    src="https://img.freepik.com/free-photo/young-beautiful-woman-pink-warm-sweater-natural-look-smiling-portrait-isolated-long-hair_285396-896.jpg"
                    alt="review IMG1"
                    className="md:w-32 md:h-32 w-20 h-20  rounded-full "
                  />
                </div>
              </div>
              <div className="text-center md:px-32 px-5 mt-5">
                <blockquote class="md:text-xl italic font-semibold text-gray-900 dark:text-white">
                  <p>
                    "Flowbite is just awesome. It contains tons of predesigned
                    components and pages starting from login screen to complex
                    dashboard. Perfect choice for your next SaaS application."
                  </p>
                </blockquote>
              </div>
            </div>
            {/* 4th Slide */}
            <div className="mt-10">
              <div className="flex justify-center items-center">
                <div>
                  <img
                    src="https://img.freepik.com/free-photo/indoor-picture-cheerful-handsome-young-man-having-folded-hands-looking-directly-smiling-sincerely-wearing-casual-clothes_176532-10257.jpg"
                    alt="review IMG1"
                    className="md:w-32 md:h-32 w-20 h-20 rounded-full "
                  />
                </div>
              </div>
              <div className="text-center md:px-32 px-5 mt-5">
                <blockquote class="md:text-xl italic font-semibold text-gray-900 dark:text-white">
                  <p>
                    "Flowbite is just awesome. It contains tons of predesigned
                    components and pages starting from login screen to complex
                    dashboard. Perfect choice for your next SaaS application."
                  </p>
                </blockquote>
              </div>
            </div>
          </Carousel>
          ;
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
