import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="">
      <section className="bg-gray-200">
        <nav className="navbar bg-gray-200">
          <div className="navbar-container container max-w-screen-xl mx-auto">
            <input type="checkbox" name="" id="" />
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
            <ul className="menu-items ">
              <li>
                <Link href="#">Home</Link>
              </li>
              <li>
                <Link href="#">Courses</Link>
              </li>
              <li>
                <Link href="#">Instructor</Link>
              </li>
              <li>
                <Link href="#">Gallery</Link>
              </li>
              <li>
                <Link href="#">Blog</Link>
              </li>
              <li>
                <Link href="#">Contact</Link>
              </li>
              <li className="bg-red-500 py-1 rounded">
                <Link href="#" className="web-service">
                  Web Service
                </Link>
              </li>
            </ul>
            <div className="logo flex items-center uppercase">
              <Icon icon="logos:coda-icon" width={30} />
              <span className="text-2xl">ducation</span>
            </div>
            {/* <div>
              <img
                src="https://www.crewbd.net/static/media/crew-logo.20d0bc2959350f6ddcf8.png"
                alt="Company Logo"
                className="w-32 logo md:px-0 px-5 md:pt-0 pt-1"
              />
            </div> */}
          </div>
        </nav>
      </section>
    </div>
  );
};

export default Header;
