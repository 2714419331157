import React from "react";
import heroBannerImg from "../../../Assets/hero.png";
import heroBackImg from "../../../Assets/hero-back.png";
import { HiLightBulb } from "react-icons/hi";
import { MdSchool } from "react-icons/md";
import { FaSmileBeam } from "react-icons/fa";

const Banner = () => {
  return (
    <div className="bg-gray-200 pt-24 md:pt-0">
      <section className="max-w-screen-xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 md:gap-10 lg:gap-10 h-screen">
          <div className="flex items-center md:text-start text-center md:p-0 p-6">
            <div className="leading-tight">
              <h1 className="md:text-[50px] text-[25px] font-bold text-[#0e1133]">
                I AM Educated.
              </h1>
              <h1 className="md:text-[50px] text-[25px] font-bold text-[#0e1133]">
                ARE YOU?
              </h1>
              <p className="md:pr-10 md:text-[22px] md:font-bold text-[#53545b] mt-5 text-center md:text-start ">
                Build your career. You`re guaranteed to find something that`s
                right for you.
              </p>
            </div>
          </div>
          <div className="relative flex items-center justify-center h-screen z-10 md:mt-0 -mt-24 ">
            <div className="flex items-center relative">
              <img
                src={heroBannerImg}
                alt="banner IMG"
                className="relative w-full md:h-screen"
              />
              <img
                src={heroBackImg}
                alt="Back Img"
                className="absolute -z-10 bottom-[10%] left-0 w-[100%]"
              />
            </div>

            <div className="bounce-out-down absolute md:top-[44%] top-[35%] left-0 md:left-[-7%]  bg-white py-2 px-6 rounded">
              <div className="flex items-center justify-center md:gap-4 gap-2 md:py-2">
                <div className="bg-orange-500 rounded-full md:w-10 w-8 md:h-10 h-8 flex items-center justify-center">
                  <HiLightBulb className="md:text-[35px] text-[25px] text-white p-1" />
                </div>
                <div className="">
                  <h5 className="md:text-[22px] text-xs font-bold text-[#0e1133] md:mb-2">
                    Congratulations
                  </h5>
                  <h5 className="md:text-[16px] text-xs text-[#53545b]">
                    You are!
                  </h5>
                </div>
              </div>
            </div>
            <div className="bounce-in hero_promo absolute bottom-[20%] md:left-[-11%] left-0 bg-white py-2 px-6 rounded">
              <div className="flex items-center md:gap-4 gap-2 md:py-2">
                <div className="bg-blue-500 rounded-full md:w-10 w-8 md:h-10 h-8 flex items-center justify-center">
                  <MdSchool className="text-[35px] text-white p-1" />
                </div>
                <div>
                  <h5 className="md:text-[22px] text-xs font-bold text-[#0e1133] md:mb-2">
                    150+
                  </h5>
                  <h5 className="md:text-[16px] text-xs text-[#53545b]">
                    Assisted Students
                  </h5>
                </div>
              </div>
            </div>
            <div className="bounce-out-down hero_promo absolute md:bottom-[30%] bottom-[35%] md:right-[-2%] right-0  bg-white py-2 px-8 rounded">
              <div className="flex items-start md:gap-4 gap-2 md:py-2">
                <div className="bg-red-500 rounded-full md:w-10 w-8 md:h-10 h-8 flex justify-center items-center">
                  <FaSmileBeam className="md:text-[35px] text-[25px] text-white p-1" />
                </div>
                <div>
                  <h5 className="md:text-[22px] text-xs font-bold text-[#0e1133] mb-2">
                    User Experience Class
                  </h5>
                  <h5 className="md:text-[16px] text-xs text-[#53545b] md:mb-5 mb-2">
                    Set your goal
                  </h5>
                  <span className="bg-[#53545b] text-white md:px-5 md:py-2 py-1 px-2 font-bold rounded hover:cursor-pointer md:text-[16px] text-[12px] hover:bg-red-500">
                    Join Now
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
