import React, { useState } from "react";
import CoursesItems from "./CoursesMenu";
import { TbCurrencyTaka } from "react-icons/tb";
import { VscArrowSmallRight } from "react-icons/vsc";
import { FiBook } from "react-icons/fi";
import { GiDuration } from "react-icons/gi";

const Courses = () => {
  const [items, setItems] = useState(CoursesItems);

  const filterItem = (categoryItem) => {
    const updatesItems = CoursesItems.filter((currentElement) => {
      return currentElement.category === categoryItem;
    });
    console.log(updatesItems);
    setItems(updatesItems);
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div className="bg-gray-200 courses py-10">
      <section className="max-w-screen-xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10">
          <div className="md:py-20 pt-20 px-5 md:px-0">
            <h1 className="text-[#0e1133] md:text-[40px] text-[22px] font-bold leading-tight">
              Find The Right <br /> Offline / Online Course For You
            </h1>
            <p className="text-[#53545b] mt-2">
              You don't have to struggle alone, you've got our assistance and
              help.
            </p>
          </div>
          <div className="flex self-center md:mt-28 mt-10">
            <div className="md:flex gap-5 text-[#252525] max-w-screen-lg mx-auto md:content-end">
              <div onClick={() => setItems(CoursesItems)}>
                <div
                  onClick={() => toggleTab(6)}
                  className={toggleState === 6 ? "active text-red-500" : ""}
                >
                  <h1 className="font-bold hover:cursor-pointer relative text-red-500">
                    See All
                    <span className="tag">new</span>
                  </h1>
                </div>
              </div>
              <div onClick={() => filterItem("design")}>
                <div
                  onClick={() => toggleTab(2)}
                  className={toggleState === 2 ? "active text-red-500" : " "}
                >
                  {" "}
                  <h1 className="font-bold hover:cursor-pointer">Design</h1>
                </div>
              </div>
              <div onClick={() => filterItem("development")}>
                <div
                  onClick={() => toggleTab(3)}
                  className={toggleState === 3 ? "active text-red-500" : " "}
                >
                  <h1 className="font-bold hover:cursor-pointer">
                    Development
                  </h1>
                </div>
              </div>
              <div onClick={() => filterItem("marketing")}>
                <div
                  onClick={() => toggleTab(4)}
                  className={toggleState === 4 ? "active text-red-500" : " "}
                >
                  <h1 className="font-bold hover:cursor-pointer">Marketing</h1>
                </div>
              </div>
              <div onClick={() => filterItem("lifestyle")}>
                <div
                  onClick={() => toggleTab(5)}
                  className={toggleState === 5 ? "active text-red-500" : " "}
                >
                  <h1 className="font-bold hover:cursor-pointer">Lifestyle</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className=" my-10">
            <div className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 justify-items-center justify-center gap-y-20 gap-x-14 mt-10 mb-5">
              {items.map((courseItems) => {
                const { name, image, price, duration, leason, category } =
                  courseItems;

                return (
                  <div className="">
                    <section class="">
                      <div class="w-72 bg-white shadow-md rounded-xl relative card-img-top">
                        <a href="#">
                          <img
                            src={image}
                            alt="Product"
                            class="w-72 rounded-t-xl"
                            // duration-500 hover:scale-105 hover:shadow-xl relative
                          />
                          <span className="absolute top-5 left-5 bg-white px-3 py-[2px] capitalize rounded font-bold text-gray-700">
                            {category}
                          </span>
                          <div class="py-3 w-72">
                            <div className="px-4 text-gray-400 mb-2 flex justify-between">
                              <div className="flex items-center gap-2">
                                <FiBook />
                                <p>Classes: {leason}</p>
                              </div>
                              <div className="flex items-center gap-2">
                                <GiDuration />
                                <p>{duration}</p>
                              </div>
                            </div>

                            <p class="px-4 text-lg font-bold text-black truncate block capitalize mb-5">
                              {name}
                            </p>
                            <hr className="w-ful" />
                            <div class="flex items-center px-4">
                              <p class="flex items-center gap-1 text-lg font-semibold text-red-500 cursor-auto my-3">
                                <TbCurrencyTaka className="text-2xl" /> {price}
                              </p>
                              {/* <del>
                                <p class="text-sm text-gray-600 cursor-auto ml-2">
                                  $199
                                </p>
                              </del> */}
                              <div class="ml-auto">
                                <p className="text-[14px] flex items-center hover:text-gray-600">
                                  Know Details{" "}
                                  <VscArrowSmallRight className="text-[24px] mt-1" />
                                </p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </section>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Courses;
