import React from "react";
import About from "../About/About";
import Banner from "../Banner/Banner";
import Become from "../Become/Become";
import ChooseUs from "../ChooseUs/ChooseUs";
import Courses from "../Courses/Courses";
import Testimonial from "../Testimonial/Testimonial";
import WhatWeOffer from "../WhatWeOffer/WhatWeOffer";

const Home = () => {
  return (
    <div>
      <Banner></Banner>
      <About></About>
      <Courses></Courses>
      <Become></Become>
      <ChooseUs></ChooseUs>
      <Testimonial></Testimonial>
      <WhatWeOffer></WhatWeOffer>
    </div>
  );
};

export default Home;
