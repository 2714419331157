import React from "react";

const Become = () => {
  return (
    <div className="max-w-screen-xl mx-auto py-20">
      <div className="text-center px-5 md:px-0">
        <h1 className="text-[#0e1133] md:text-[40px] text-[25px] font-bold mb-2">
          What Is Skilline?
        </h1>
        <p className="md:px-80 text-[#53545b]">
          Sloshed faff about me old mucker blatant bubble and squeak hanky panky
          some dodgy chav bevvy arse chimney pot I, ruddy plastered buggered
          smashing blow off I'm telling up the kyver he legged it bleeder jolly
          good,
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10 my-20 relative px-5 md:px-0">
        <div className="int">
          <div className="intern w-full h-80">
            <h1 className="md:text-[30px] text-[18px] font-bold text-white leading-tight text-center">
              Opportunities <br /> as a Intern
            </h1>
            <p className="mt-5">
              <span className="text-center text-white border md:py-3 md:px-14 py-2 px-8 text-[16px] md:text-[20px] font-bold hover:cursor-pointer hover:bg-red-500">
                Join
              </span>
            </p>
          </div>
        </div>
        <div className="ins">
          <div className="instructor w-full h-80">
            <h1 className="md:text-[30px] text-[18px]  font-bold text-white leading-tight text-center">
              Become
              <br /> an Instructor
            </h1>
            <p className="mt-5">
              <span className="text-center text-white border md:py-3 md:px-14 py-2 px-8 text-[16px] md:text-[20px] font-bold hover:cursor-pointer hover:bg-red-500">
                Join
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Become;
