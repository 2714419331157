const CoursesItems = [
  {
    id: 1,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Creative Graphics Design",
    category: "design",
    price: 40000,
    duration: "6 Months",
    leason: 38,
  },
  {
    id: 2,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Advance Graphics Design",
    category: "design",
    price: 60000,
    duration: "8 Months",
    leason: 48,
  },
  {
    id: 3,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Motion Graphics Design",
    category: "design",
    price: 40000,
    duration: "6 Months",
    leason: 24,
  },
  {
    id: 4,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Advance Motion Design",
    category: "design",
    price: 60000,
    duration: "8 Months",
    leason: 48,
  },
  {
    id: 5,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Web Design",
    category: "design",
    price: 20000,
    duration: "6 Months",
    leason: 30,
  },
  {
    id: 6,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "3D Modeling Design",
    category: "design",
    price: 40000,
    duration: "6 Months",
    leason: 24,
  },
  {
    id: 7,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Digital Art",
    category: "design",
    price: 40000,
    duration: "6 Months",
    leason: 22,
  },
  {
    id: 8,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Interior Design",
    category: "40000",
    price: 350,
    duration: "6 Months",
    leason: 24,
  },
  {
    id: 9,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Web Development",
    category: "development",
    price: 40000,
    duration: "6 Months",
    leason: 38,
  },
  {
    id: 10,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "MERN Stack",
    category: "development",
    price: 40000,
    duration: "6 Months",
    leason: 38,
  },
  {
    id: 11,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Android App Development",
    category: "development",
    price: 40000,
    duration: "6 Months",
    leason: 38,
  },
  {
    id: 12,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Digital Marketing",
    category: "marketing",
    price: 30000,
    duration: "4 Months",
    leason: 20,
  },
  {
    id: 13,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Advance Facebook Marketing",
    category: "marketing",
    price: 10000,
    duration: "2 Months",
    leason: 10,
  },
  {
    id: 14,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Photography",
    category: "lifestyle",
    price: 30000,
    duration: "4 Months",
    leason: 16,
  },
  {
    id: 15,
    image:
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSG8qE5F1KnkXgm0VmRNsY66oBUtRoc8-2glg&usqp=CAU",
    name: "Video Content Making",
    category: "lifestyle",
    price: 20000,
    duration: "3 Months",
    leason: 15,
  },
];

export default CoursesItems;
