import logo from "./logo.svg";
import "./App.css";
import Header from "./FrontEnd/Shared/Header/Header";
import Footer from "./FrontEnd/Shared/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import Home from "./FrontEnd/Layout/Homepage/Home";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    function handleContextMenu(e) {
      e.preventDefault();
    }

    const rootElement = document.getElementById("my-app");

    if (rootElement) {
      rootElement.addEventListener("contextmenu", handleContextMenu);

      return () => {
        rootElement.removeEventListener("contextmenu", handleContextMenu);
      };
    }
  }, []);

  // For Keyboard Function
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey || event.keyCode === 123) {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div id="my-app">
      <Header></Header>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
