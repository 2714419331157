import React from "react";
import graduationIcon from "../../../Assets/graduation.png";
import jobPlacementIcon from "../../../Assets/coffee.png";
import lifeTimeIcon from "../../../Assets/business.png";
import internIcon from "../../../Assets/feather.png";
import { TiTick } from "react-icons/ti";
import aboutImg from "../../../Assets/about.jpg";
import courseImg from "../../../Assets/about-banner.jpg";

const About = () => {
  return (
    <div className="max-w-screen-xl mx-auto pt-32">
      <div className="px-2 md:px-0">
        <h1 className="text-center text-[#0e1133] md:text-[40px] text-[18px] font-bold mb-2">
          Why An Scholercity Out Of The Ordinary
        </h1>
        <p className="text-center text-[#53545b] md:text-[20px] text-[14px]">
          You don't have to struggle alone, you've got our assistance and help.
        </p>
      </div>
      {/* 4 Benefits */}
      <div className="mt-20 px-5 md:px-0">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-4 gap-5 md:gap-8 lg:gap-8 ">
          <div className="bg-[#2d69f0] shadow-lg rounded transform hover:-translate-y-6 duration-500 ease-in-out overflow-hidden w-full">
            <div className="text-white p-10">
              <img src={graduationIcon} alt="Icon" className="w-14" />
              <h2 className="md:text-[22px] font-bold my-5">
                Online Or Offline <br /> Classes
              </h2>
              <p className="md:text-[14px] text-white">
                Don't miss that courses, Join now!
              </p>
            </div>
          </div>
          <div className="bg-red-500 shadow-lg rounded transform hover:-translate-y-6 duration-500 ease-in-out overflow-hidden w-full">
            <div className="text-white p-10">
              <img src={jobPlacementIcon} alt="Icon" className="w-14" />
              <h2 className="md:text-[22px] font-bold my-5">
                Job Placement <br /> Support
              </h2>
              <p className="md:text-[14px] text-white">
                Don't miss that courses, Join now!
              </p>
            </div>
          </div>
          <div className="bg-[#8007e6] shadow-lg rounded transform hover:-translate-y-6 duration-500 ease-in-out overflow-hidden w-full">
            <div className="text-white p-10">
              <img src={lifeTimeIcon} alt="Icon" className="w-14" />
              <h2 className="md:text-[22px] font-bold my-5">
                Lifetime Slack chat support
              </h2>
              <p className="md:text-[14px] text-white">
                Don't miss that courses, Join now!
              </p>
            </div>
          </div>
          <div className="bg-[#0cae74] shadow-lg rounded transform hover:-translate-y-6 duration-500 ease-in-out overflow-hidden w-full">
            <div className="text-white p-10">
              <img src={internIcon} alt="Icon" className="w-14" />
              <h2 className="md:text-[22px] font-bold my-5">
                Research <br /> and Innovation
              </h2>
              <p className="md:text-[14px] text-white">
                Don't miss that courses, Join now!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="my-48 px-5 md:px-0">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 md:gap-10 lg:gap-10">
          <div className="relative">
            <div className="relative">
              <div className="flex gap-5 justify-around">
                <div className="rotate-90 md:-translate-y-32 -translate-y-10 md:-translate-x-32 -translate-x-24">
                  <h1 className="text-[#6d6e75]">
                    <span className="font-bold text-[#0e1133]">8,200+</span>{" "}
                    five star reviews
                  </h1>
                </div>
                <div>
                  <img src={aboutImg} alt="About img" className="w-96" />
                </div>
              </div>
            </div>
            <div className="absolute md:-bottom-10 md:right-16 -bottom-32">
              <div className="">
                <div className="flex gap-5">
                  <div>
                    <img src={courseImg} alt="" className="w-60 rounded" />
                  </div>
                  <div className="md:mt-56 mt-20">
                    <div className="flex">
                      <img
                        class="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                        src="https://img.freepik.com/free-photo/young-bearded-man-with-striped-shirt_273609-5677.jpg"
                        alt="Bordered avatar"
                      />
                      <img
                        class="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                        src="https://img.freepik.com/free-photo/cheerful-curly-business-girl-wearing-glasses_176420-206.jpg"
                        alt="Bordered avatar"
                      />
                      <img
                        class="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                        src="https://img.freepik.com/free-photo/young-man-with-charming-smile-blue-eyes-posing_176420-15602.jpg"
                        alt="Bordered avatar"
                      />
                    </div>
                    <h1 className="text-[#6d6e75] mt-3">
                      Join Over{" "}
                      <span className="font-bold text-[#0e1133]">4000+</span>{" "}
                      Students
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-40 md:pt-0">
            <h1 className="text-[#0e1133] md:text-[40px] text-[22px] font-bold mb-5 leading-tight">
              Achieve Your Goals <br /> With ...
            </h1>
            <p className="md:text-[18px] text-[#53545b] mb-4 leading-tight">
              ED is a specialized professional institute dedicated to enhance
              the younger generation of Bangladesh to transform them into
              enormously skilled and professional human resource. CREW learning
              institute is working with an aim create creative professionals in
              the sector of advertising and creative industry of Bangladesh. The
              institute in its essence believes in turning human into human
              resources. The institute was incepted with a vision to solve the
              lack of skilled human resources in advertising sector. Our vision
              is to enhance and develop Bangladeshi human resource and
              disseminate the strength globally. We want to create professionals
              who strive towards excellence and thrive in every industry.
            </p>
            <div className="pb-10 ">
              <p className="flex items-center gap-2 leading-tight">
                <span>
                  <TiTick className="text-[25px]" />
                </span>
                <span className="text-[18px] text-[#0e1133] font-semibold leading-tight">
                  Upskill your organization.
                </span>
              </p>
              <p className="flex items-center gap-2 my-2 leading-tight">
                <span>
                  <TiTick className="text-[25px]" />
                </span>
                <span className="text-[18px] text-[#0e1133] font-semibold">
                  Access more then 100K online courses.
                </span>
              </p>
              <p className="flex items-center gap-2 leading-tight">
                <span>
                  <TiTick className="text-[25px]" />
                </span>
                <span className="text-[18px] text-[#0e1133] font-semibold">
                  Learn the latest skills.
                </span>
              </p>
            </div>
            <span className="border-4 px-6 py-2 font-bold text-[#0d0f25] tracking-wide text-[18px] hover:bg-red-500 hover:cursor-pointer hover:text-white">
              Apply Now
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
