import React from "react";

const WhatWeOffer = () => {
  return (
    <div className="max-w-screen-xl mx-auto py-20">
      <div className="text-center mb-20">
        <h1 className="md:text-[40px] font-bold text-[#0e1133]">
          What you Offer!
        </h1>
        <p className="md:px-80 text-[#53545b]">
          Far far away, behind the word mountains, far from the countries
          Vokalia and Consonantia, there live the blind texts.
        </p>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10 offer">
        <figure class="effect-sadie">
          <img
            src="https://img.freepik.com/premium-psd/digital-marketing-desk-concept_23-2148498029.jpg"
            alt="img02"
          />
          <figcaption>
            <h2>
              <span>Digital Marketing</span>
            </h2>
            <p>
              Digital Marketing never took her eyes off me. <br />
              It had a dark soul.
            </p>
            <a href="#">View more</a>
          </figcaption>
        </figure>
        <figure class="effect-sadie">
          <img
            src="https://img.freepik.com/free-vector/isometric-ui-ux-landing-page-template_52683-67973.jpg"
            alt="img14"
          />
          <figcaption>
            <h2>
              <span>Web Design</span>
            </h2>
            <p>
              Sadie never took her eyes off me. <br />
              She had a dark soul.
            </p>
            <a href="#">View more</a>
          </figcaption>
        </figure>
        <figure class="effect-sadie">
          <img
            src="https://img.freepik.com/free-photo/learn-learning-education-studying-concept_53876-120493.jpg"
            alt="img14"
          />
          <figcaption>
            <h2>
              <span>Training</span>
            </h2>
            <p>
              Sadie never took her eyes off me. <br />
              She had a dark soul.
            </p>
            <a href="#">View more</a>
          </figcaption>
        </figure>
      </div>
    </div>
  );
};

export default WhatWeOffer;
